import {Navbar, Nav, Form, Button, Table, Container, Modal} from 'react-bootstrap';
import '../App.css';
import FileSelectModal from '../Modal/FileSelectModal';
import * as XLSX from "xlsx";
import db from '../firebase';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
import { Fragment } from "react";
import dateformat from "dateformat"
import { useHistory } from 'react-router';
import axios from 'axios';
import Progress from 'react-progressbar';
import LoaderUi from "../LoaderUi"
 import firebase from '../firebase';
 import * as Loader from 'react-loader-spinner'
import { removeSpaces } from '../Common/CommonVarFunc';
import { AuthContext } from '../auth/Auth';
import './CustomMessage.css'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {toTitleCase} from "../Common/CommonVarFunc"
function CustomMessage() {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [completedPart, setCompletedPart] = useState(0)
  const [showProcessingPart, setShowProcessingPart] = useState(false)
  const [totalMessages, setTotalMessages] = useState(0)
  const [sentMessages, setSentMessages] = useState(0)
  const [passcode, setPasscode] = useState("")
  const history = useHistory()
  const[message, setMessage] = useState("")
  const[sendMesaage, setSendMessage] = useState("")
  const [api, setApi] = useState("")
  const [plus, setPlus] = useState("")
  const [add91, setAdd91] = useState("")
  const [msgParamKey, setMsgParamKey] = useState("")
  const [mobileParamKey, setMobileParamKey] = useState("")
  const [allowedMedia, setAllowedMedia] = useState(false)
  const [fileSelectModalOpen, setFileSelectModalOpen] = useState(false)
  const [fileUrl, setFileUrl] = useState("")
  const [mediaCheckBox, setMediaCheckBox] = useState(false)
  const [radiobuttonValue, setRadiobuttonvalue] = useState("selectfile")
  const [fileName, setFileName] = useState("")
  const [fileType, setFileType] = useState("")
  const [mediaUrlColumn, setMediaUrlColumn] = useState("")
  const [showTextArea, setShowtextArea] = useState(true)
  const [typeTextInUrl, setTypeTextInUrl] = useState("")
  const [typeMediaInUrl, setTypeMediaInUrl] = useState("")
  const [radioButtonForSelection, setRadioButtonForSelection] = useState('importexcel')
  const [databseoptionSelect, setDatabaseOptionSelect] = useState("")
  const [registeredStudents, setRegisteredStudents] = useState([])
  const [unregisteredStudents, setUnRegisteredStudents] = useState([])
  const [registeredUsers, setRegisteredUsers] = useState([])
  const [unregisteredUsers, setUnRegisteredUsers] = useState([])
  const [admins, setAdmins] = useState([])
  const [allUsersWithoutName, setAllUsersWithoutName] = useState([])
  

  const {currentUser} = useContext(AuthContext)
  const uid = currentUser.uid
// temporary basis 
 

useEffect(()=>{

  if(mediaCheckBox && ((fileType.includes('application') && radiobuttonValue == "selectfile") || radiobuttonValue == "selectexcel")){
      window.alert("Text Messages are not allowed simultaneously with the selected file type")
      setShowtextArea(false)
  }
  else{
    setShowtextArea(true)
  }
},[fileType, radiobuttonValue, mediaCheckBox])

useEffect(()=>{
  setFileName("")
},[radiobuttonValue])

// temporary basis - End
  useEffect(()=>{
    const ref = firebase.firestore().collection("users").doc(uid)
    ref.get().then((doc)=>{
      if(doc.exists){
        // if(doc.data().whatsappportal != "yes"){
        //   window.alert("You are unauthorized to access this page !!")
        //   firebase.auth().signOut()
        // }
      }
    })

    const ref1 = firebase.firestore().collection("website").doc("whatsapp")

    ref1.get().then((doc)=>{
      setApi(doc.data().api)
      if(doc.data().add91 != undefined && doc.data().add91.toLowerCase() == "yes"){
        setAdd91("91")
      }
      if(doc.data().plus != undefined && doc.data().plus.toLowerCase() == "yes"){
        setPlus("+")
      }
      if(doc.data().allowedmedia != undefined && doc.data().allowedmedia.toLowerCase() == "yes"){
        setAllowedMedia(true)
        let mediaKey= doc.data().typemediakey
        let mediavalue = doc.data().typemediavalue
        let x = `&${mediaKey}=${mediavalue}`
        setTypeMediaInUrl(x)
      }
      if(doc.data().includetype != undefined && doc.data().includetype.toLowerCase()== "yes"){
        let textKey = doc.data().typetextkey
        let textvalue = doc.data().typetextvalue
        
        let y = `&${textKey}=${textvalue}`
       
        setTypeTextInUrl(y)
      }
      setMobileParamKey(doc.data().mobileparamkey)
      setMsgParamKey(doc.data().msgparamkey)

    })
  },[])

 



  useEffect(()=>{

    if(databseoptionSelect == "alladmins"){
      setHeaders(["Whatsapp", "Name"])
      setRows(admins)
      setShow(true)
    }
    else   if(databseoptionSelect == "registeredusers"){
      setHeaders(["Whatsapp", "Name"])
     setRows(registeredUsers)
      setShow(true)
    }else if(databseoptionSelect == "registeredstudents"){
      setHeaders(["Whatsapp", "Name"])
      setRows(registeredStudents)
      setShow(true)
    } else if(databseoptionSelect== "allusers"){
      setHeaders(["Whatsapp"])
      setRows(allUsersWithoutName)
      setShow(true)
    }else if(databseoptionSelect == "unregisteredusers"){
      setHeaders(["Whatsapp"])
      setRows(unregisteredUsers)
      setShow(true)
    }else if(databseoptionSelect == "unregisteredstudents"){
      setHeaders(["Whatsapp"])
      setRows(unregisteredStudents)
      setShow(true)
    }

  
  },[databseoptionSelect, radioButtonForSelection])
  

  function DateTimeString(date){
    
    let mon = date.getMonth()
    let dat = date.getDate()
    let ye = date.getFullYear()
  let min = date.getMinutes()
  let sec = date.getSeconds() 
  let hh = date.getHours()
  
  let finalDate = `${dat}/${mon}/${ye} ${hh}:${min}:${sec}`
  return finalDate
   
  }
  const handleClose = () => {
    resetModal();
    setDatabaseOptionSelect("")
  }
  const handleShow = () => setShow(true);
  
  const processSheetData = (excelData) => {
    const wsname = excelData.SheetNames[0];
    const ws = excelData.Sheets[wsname];

    const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
    let csv = [];
    let headers = [];
    for (let i = 0; i < dataParse.length; i++) {
      if (dataParse[i] === "") continue;
      let fields = dataParse[i];
      if (i === 0) {
        headers = fields;
      } else {
        let csvRow = [];
        for (let field of fields) {
          if (!isNaN(field))
            field = Number(field);
          csvRow.push(field);
        }
        csv.push(csvRow);
      }
    }
    setHeaders(headers);
    setRows(csv);
  }
  
  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});

      processSheetData(readedData);
    };
    reader.readAsBinaryString(file);
  };

  const resetModal = () => {
    setShow(false);
    setHeaders([]);
    setRows([]);
  }

  function SendDataToFirebase(total, msg){
    const x = new Date()
    const id = (x.getTime()).toString()
    const datetime =  x.toString()
    const ref = firebase.firestore().collection("messages").doc(id)
    ref.set({
      msg : msg,
      totalno : total.toString(),
      id: id,
      datetime : datetime,
      userid : uid
    })

  }
 function handleSubmit () {
  setShowProcessingPart(true)
    const formattedValues = rows.map((row) => {
     
    
        return {
      A: row[0] != undefined ? removeSpaces(row[0].toString().trim()) :"",
      B: row[1] != undefined ? row[1].toString():"",
      C: row[2] != undefined ? row[2].toString():"",
      D: row[3] != undefined ? row[3].toString():"",  
     E: row[4] != undefined ? row[4].toString():"",
     F: row[5] != undefined ? row[5].toString():"",
     G: row[6] != undefined ? row[6].toString():"",
     H: row[7] != undefined ? row[7].toString():"",
     I: row[8] != undefined ? row[8].toString():"",
     J: row[9] != undefined ? row[9].toString():"",
     K: row[10] != undefined ? row[10].toString():"",
     L: row[11] != undefined ? row[11].toString():"",
     M: row[12] != undefined ? row[12].toString():"",
     N: row[13] != undefined ? row[13].toString():"",
     O: row[14] != undefined ? row[14].toString():"",
     P: row[15] != undefined ? row[15].toString():"",
     Q: row[16] != undefined ? row[16].toString():"",
     R: row[17] != undefined ? row[17].toString():"",
     S: row[18] != undefined ? row[18].toString():"",
     T: row[19] != undefined ? row[19].toString():"",
     U: row[20] != undefined ? row[20].toString():"",
     
      }
     
    });
    SendDataToFirebase(formattedValues.length,message)
    setTotalMessages(formattedValues.length)
   
    let counter =0
    for(let i=0; i< formattedValues.length; i++){
    setTimeout(()=>{
     let msg1 = message.replaceAll('#A#', formattedValues[i].A)
     let msg2 =  msg1.replaceAll('#B#', formattedValues[i].B)
     let msg3 = msg2.replaceAll("#C#", formattedValues[i].C)
     let msg4 = msg3.replaceAll("#D#", formattedValues[i].D)
     let msg5 = msg4.replaceAll("#E#", formattedValues[i].E)
     let msg6 = msg5.replaceAll("#F#", formattedValues[i].F)
     let msg7 = msg6.replaceAll("#G#", formattedValues[i].G)
     let msg8 = msg7.replaceAll("#H#", formattedValues[i].H)
     let msg9 = msg8.replaceAll("#I#", formattedValues[i].I)
     let msg10 = msg9.replaceAll("#J#", formattedValues[i].J)
     let msg11 = msg10.replaceAll("#K#", formattedValues[i].K)
     let msg12 = msg11.replaceAll("#L#", formattedValues[i].L)
     let msg13 = msg12.replaceAll("#M#", formattedValues[i].M)
     let msg14 = msg13.replaceAll("#N#", formattedValues[i].N)
     let msg15 = msg14.replaceAll("#O#", formattedValues[i].O)
     let msg16 = msg15.replaceAll("#P#", formattedValues[i].P)
     let msg17 = msg16.replaceAll("#Q#", formattedValues[i].Q)
     let msg18 = msg17.replaceAll("#R#", formattedValues[i].R)
     let msg19 = msg18.replaceAll("#S#", formattedValues[i].S)
     let msg20 = msg19.replaceAll("#T#", formattedValues[i].T)
     let msg21 = msg20.replaceAll("#U#", formattedValues[i].U)
    let msg23 = encodeURIComponent(msg21)
   setSentMessages(i+1)
   counter = ((i+1)/formattedValues.length)*100  
   setCompletedPart(counter)

    if(!mediaCheckBox){
      axios.get(`${api}${typeTextInUrl}&${mobileParamKey}=${plus}${add91}${formattedValues[i].A}&${msgParamKey}=${msg23}`)
      .then((res)=>{
      
      }).catch((err)=>{
  
      })
      
    }

    if(mediaCheckBox && radiobuttonValue == "selectfile"){
      if(fileUrl == ""){
        window.alert("File Not attached")
      }else{
        console.log(typeMediaInUrl)
        let fileUrlSent = encodeURIComponent(fileUrl)
        axios.get(`${api}${typeMediaInUrl}&${mobileParamKey}=${plus}${add91}${formattedValues[i].A}&${msgParamKey}=${msg23}&media_url=${fileUrlSent}&filename=${fileName}`)
        .then((res)=>{
        
        }).catch((err)=>{
    
        })
      }}

      if(mediaCheckBox && radiobuttonValue == "selectexcel"){
        let mediaurl = formattedValues[i][mediaUrlColumn.toUpperCase()]
        let fileName1 = formattedValues[i][fileName.toUpperCase()]
        let fileNameSent = encodeURIComponent(fileName1)
        let mediaurlSent = encodeURIComponent(mediaurl)
          axios.get(`${api}${typeMediaInUrl}&${mobileParamKey}=${plus}${add91}${formattedValues[i].A}&${msgParamKey}=${msg23}&media_url=${mediaurlSent}&filename=${fileNameSent}`)
          .then((res)=>{
          
          }).catch((err)=>{
      
          })
      
    }
  
    



    if(i== (formattedValues.length-1)){
      window.alert("Process Completed")
      resetModal()
      setFileType("")
      setFileName("")
      setFileUrl("")
      setMediaUrlColumn("")
      setMediaCheckBox(false)
      setMessage("")
    setShowProcessingPart(false)
    }
  },8000*i)
    }

 
}
function SignOut(){
  let x = window.confirm("Are you sure ?")
  if(x){
    firebase.auth().signOut()
  }
}

  return (
    <>
    {fileSelectModalOpen ? <FileSelectModal setFileurl={setFileUrl} setOpenModal={setFileSelectModalOpen} setFileType={setFileType}/> :""}
   {!showProcessingPart ?   <ExitToAppIcon style={{float:"right", margin:"10px", cursor:"pointer", fontSize:"50px", color:"#2a3a8d"}} onClick={SignOut}/> :""}
    {showProcessingPart ? <> 
    <div style={{height:"100vh", overflow:"hidden"}}>
      <div style={{marginTop:"1vh", marginRight:"2vw", marginLeft:"2vw"}}>
        <center>
        <LoaderUi/>

      <h3>Total Submitted Messages = {totalMessages}</h3>   <br/>
      <h3>Processed Messages = {sentMessages}</h3> </center>
      <br/>
      <Progress  color="red"  completed={completedPart} /> <br/>
      <center><h2 style={{color:"red"}}>Please don't close/ refresh the tab  until the process completes !</h2></center>
      </div>
      </div></> :
    <>
         <Navbar.Brand ><h1> VIPCA Custom Messaging (Whatsapp)</h1>  </Navbar.Brand>
         <br/>
         {allowedMedia? <>
         
         <input type="checkbox" checked={mediaCheckBox} onChange={()=> setMediaCheckBox(!mediaCheckBox)} style={{cursor:"pointer", fontSize:"20px", marginLeft:"2vw"}}/> Include Media 
         {mediaCheckBox ? 
         <>
         <br/>
         <input type="radio" value="selectfile" name="includemedia" onChange={(e)=> setRadiobuttonvalue(e.target.value) } checked={radiobuttonValue === "selectfile"} style={{marginLeft:"2vw"}}/> Select Media
         <input type="radio" value="selectexcel" name="includemedia"onChange={(e)=> setRadiobuttonvalue(e.target.value) } checked={radiobuttonValue === "selectexcel"} style={{marginLeft:"1vw"}}/> Include Media Url from Excel <br/>
         {radiobuttonValue == "selectfile" ?<> <button onClick={()=> setFileSelectModalOpen(true)} style={{marginLeft:"2vw",cursor:"pointer", position:"inline"}}  className="btn btn-primary">Select File</button> {fileUrl !=""?  <p style={{color:"red", fontSize:"20px",marginLeft:"2vw", position:"inline"}}>1 File Selected</p>:<p style={{color:"red", fontSize:"20px",marginLeft:"1vw", position:"inline"}}>No File Selected</p>}</>:"" }
         {radiobuttonValue == "selectexcel" ? <div style={{marginLeft:"2vw"}}><label>Media Url Variable (Excel Column Value)</label><input type="text" maxLength={1} value={mediaUrlColumn} onChange={(e)=>setMediaUrlColumn(e.target.value)} style={{borderRadius:"5px", marginLeft:"1vw"}}/> <br/>
         </div>:"" }
         <label style={{marginLeft:"2vw"}} >File Name {radiobuttonValue === "selectexcel" ?  "(Excel Column Value)" :""}</label><input type="text"value={fileName} onChange={(e)=> setFileName(e.target.value)} maxLength={radiobuttonValue === "selectexcel" ? 1 : 100} style={{borderRadius:"5px", marginLeft:"1vw"}}/>
          </>:""} 
        
          </>:""}<br/>


         {showTextArea && (   <textarea onChange={(e)=> setMessage(e.target.value)} style={{margin:"1%", height:"20vh", width:"80vw",fontSize:"20px"}}  value={message}></textarea> )} <br/>
           
          
   
        <Navbar bg="light" expand="lg">
        <Container>
        Note :- 
        {/* <ExportCSV  buttonText={"Export Student"} csvData={studentArray} fileName={'Student ICAI Dhanabad'}/> */}
        <ol >
        <li> <b>1. </b>Please write the message before import. Post Import writing message won't be allowed.</li>
        <li><b>2. </b>Please use variables such as #A# upto #U# for importing excel values.</li>
        <li><b>3. </b>Numbers will always be in #A# and rest upto 20 variables can be used in #B# to #U# </li>
        </ol>
        
        <input type="radio" value="importexcel" name="numberselection" onChange={(e)=> setRadioButtonForSelection(e.target.value) } checked={radioButtonForSelection === "importexcel"} style={{marginLeft:"2vw"}}/> Import From Excel
         <input type="radio" value="importdatabase" name="numberselection"onChange={(e)=> setRadioButtonForSelection(e.target.value) } checked={radioButtonForSelection === "importdatabase"} style={{marginLeft:"1vw"}}/>Select from Database <br/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
        {rows.length == 0  && radioButtonForSelection == "importexcel"?    <Button  onClick={handleShow} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer", backgroundColor:"#2a3a8d", color:"white", borderRadius:"5px"}}>Import Excel</Button> :""} 
          </Navbar.Collapse>
        </Container>
        <Modal.Header >
           {rows.length==0  && radioButtonForSelection == "importexcel" ? <Modal.Title>Import a CSV or XLSX</Modal.Title> :""} 
           {radioButtonForSelection == "importdatabase" ? <> <label>Select Your Option</label>
        <select value={databseoptionSelect} onChange={(e)=> setDatabaseOptionSelect(e.target.value)}>
        <option value="">Choose Your Option</option>
          <option value="alladmins">All Admins</option>
          <option value="registeredusers">Registered Users</option>
          <option value="unregisteredusers">Unregistered Users Without Name</option>
          <option value="allusers"> All Users Without Name</option>
          <option value="registeredstudents"> Registered Students</option>
          <option value="unregisteredstudents"> Unregistered Students Without Name</option>
        </select><br/> </>:""}
        
            {rows.length>0 ?
            <Button variant="primary" onClick={handleSubmit} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer",  backgroundColor:"#2a3a8d", color:"white", borderRadius:"5px"}}>
              Send Messsage
            </Button>:""}
            {show ?<Button variant="secondary" onClick={handleClose} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer",  backgroundColor:"#2a3a8d", color:"white", borderRadius:"5px"}}>
              Cancel
            </Button> :""}
          </Modal.Header>
      </Navbar>
      <Container style={{overflowY:"scroll"}}>
        <Modal show={show} onHide={handleClose}  style={{overflowY:"scroll", height:"30vh", width:"100%"}}>
     
          <Modal.Body>
            {headers.length < 1 ||  rows.length < 1 ?
              <Form.Group>
                <Form.File id="file-upload" onChange={event => handleFileUpload(event) } style={{ fontSize:"20px", color:"black", borderRadius:"5px"}}/>
              </Form.Group>
              :
              <Table striped bordered hover style={{overflowY:"scroll"}}>
                <thead>
                  <tr>
                    <th>#</th>
                    {headers.map((item, index) => (
                      <th key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.length && rows.map((itemArray, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{itemIndex+1}</td>
                      {itemArray.map((item, index) => (
                        <td key={index}>
                          {item}
                        </td>
                      ))}
                    </tr>))
                  }
                </tbody>
              </Table>
            }
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Table striped bordered hover>
         
          <tbody>
          {users.length < 1 &&
            <tr>
              {/* <td colSpan={3}>No users found.</td> */}
            </tr>
          }
          {users && users.map((user, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{user.name}</td>
              <td>{user.age1}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Container>
      </>}
   
    </>

  );
}

export default CustomMessage;