import React, { useEffect, useState } from "react";
import ModalLoaderUi from "../Common/ModalLoader";
import firebase from "../firebase"
import "./Modal.css";

function FileSelectModal({ setOpenModal, setFileurl, setFileType}) {
  
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  function OnChangeHandler(e){
    let file = e.target.files[0]
    if(file['type'].includes("image") || file['type'].includes("application/pdf") ||  file['type'].includes("application/msword")){
    setLoading(true)
   
    let filename=""
   
 
  if(file['type'].includes("image")){
    filename = "image.png"
  }
  if(file['type'].includes("application/pdf")){
    filename = "abc.pdf"
  }
  if(file['type'].includes("application/msword")){
    filename = "abc.doc"
  }
  setFileType(file['type'])
   firebase.app().storage("gs://vip-ca.appspot.com").ref().child(filename).put(file).then((doc)=>{
    doc.ref.getDownloadURL().then((url)=>{
      setFileurl(url)
      setOpenModal(false)
    })
   })
 } 
 else{
   window.alert("Invalid File Type!!\nAllowed file types:- images,.doc,.pdf")
  setShowError(true)
 }
  }
  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{maxWidth:"auto !important"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title" >
          <h2>Select File</h2>

        </div>
        <div className="body">
          {loading ? <ModalLoaderUi/>:<>
            <input type="file" accept="image/*,.pdf,.doc" onChange={(e)=> OnChangeHandler(e)} /> <br/>
            {showError ? <>Please select other file</> :""}
            </>
          }
        </div>
        <div className="footer">
          {/* <button
            onClick={() => {
              setOpenModal(false);
            }}
         
          >
          Okay
          </button> */}
      
        </div>
      </div>
    </div>
  );
}

export default FileSelectModal;