import "firebase/auth";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBwaXlnu7a9DN9h7fM-g4fx6qMlgMvzoZo",
  authDomain: "vip-ca.firebaseapp.com",
  databaseURL: process.env.DATABASE_URL,
  projectId: "vip-ca",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID

  };

  firebase.initializeApp(firebaseConfig);

  
export default firebase;